import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React, { ChangeEvent, ComponentProps, createRef, FormEvent, forwardRef, MutableRefObject, ReactNode, useCallback, useRef, useState, useMemo } from "react";
import exclamation from "../../assets/exclamation.svg";
import selectArrow from "../../assets/selectArrow.svg";
import { verticalMargin } from "../../margins";
import { bpLarge, colorGoldStroke } from "../../stylingConstants";
import { copyObjectWithPropValue, omit, typedKeys } from "../../utils";
import useValidation, { email, required, Validator, maxLength } from "../../validation";
import { Button, FormButton } from "../Atoms/Button";
import { captionTypeface, displayTypeface } from "../Atoms/Text";
import { Link } from "@reach/router";
import { useGlobalSiteData } from "../../hooks";

type IteratorItem<T> = T extends Iterator<infer Item> ? Item : never;

const contentMaxLong = 800;
const contentMidLong = 150;

const categories = new Map([
  ["Årets Avslöjande", <>Årets Avslöjande tillfaller den under året vars granskande journalistik lyft fram nya uppgifter som lett till ett starkt genomslag och varaktiga effekter.</>],
  ["Årets Förnyare", <>Årets Förnyare tillfaller den som under året förnyat journalistiken genom att överraska, utforska, eller på annat sätt visat prov på journalistisk originalitet och nyfikenhet.</>],
  ["Årets Berättare", <>Årets Berättare tillfaller den som under året med hög stilistisk kvalitet gett oss en journalistisk berättelse som berör och består.</>],
  ["Årets Röst", <>Årets Röst tillfaller den som under året med personlighet och journalistisk kvalitet gjort ett bestående avtryck i medielandskapet.</>],
  ["Lukas Bonniers Stora Journalistpris", <>Lukas Bonniers Stora Journalistpris delas ut till en journalist som under lång tid visat prov på skicklighet och framstående journalistisk gärning.</>],
] as const);

type Category = IteratorItem<ReturnType<(typeof categories)["keys"]>>;

const lengthPresentation = (length: number, maxLength: number) => (
  <>
    {length ? "Antal tecken kvar" : "Max antal tecken"}: <strong>{maxLength - length || 0}</strong>
  </>
);

export default function NominateForm({ setComplete }: { setComplete(): void }) {
  const { privacyPolicyUrl } = useGlobalSiteData();
  const [form, setForm] = useState({
    ["form-name"]: "Lukas Bonniers Stora Journalistpris" as Category,
    författare: "",
    titel: "",
    publiceringsdatum: "",
    ["länk-till-material"]: "",
    fil: null as File | null,
    motivering: "",
    avslöjande: "",
    "avslöjande-följd": "",
    nyskapande: "",
    "nyskapande-för-konsument": "",
    berättare: "",
    "inskickad-av": "",
    "inskickad-av-epost": "",
    acceptTerms: false,
  });

  const [dirtyFields, setDirtyFields] = useState(copyObjectWithPropValue(form, false));

  const ifCategory = <T extends any>(category: Category, validator: Validator<T>) => (form["form-name"] === category ? validator : ((() => {}) as Validator<T>));

  const errors = useValidation(
    {
      "form-name": [required("Kategori måste väljas")],
      författare: [required("Ange namn på bidragets författare eller upphovsman")],
      fil: [],
      "länk-till-material": [],
      motivering: [required("Ange en motivering till bidraget."), maxLength(contentMaxLong, (length) => `Motivering får vara max ${length} tecken`)],
      publiceringsdatum: [],
      titel: [required("Ange bidragets titel")],
      avslöjande: [ifCategory("Årets Avslöjande", required("Beskriv avslöjande")), maxLength(150, (length) => `Texten är för lång, max ${length} tecken.`)],
      "avslöjande-följd": [ifCategory("Årets Avslöjande", required("Beskriv avslöjandets konsekvenser.")), maxLength(800, (length) => `Texten är för lång, max ${length} tecken.`)],
      nyskapande: [ifCategory("Årets Förnyare", required("Beskriv hur personen förnyat.")), maxLength(800, (length) => `Texten är för lång, max ${length} tecken.`)],
      "nyskapande-för-konsument": [ifCategory("Årets Förnyare", required("Beskriv vad förnyandet lett till.")), maxLength(800, (length) => `Texten är för lång, max ${length} tecken.`)],
      berättare: [ifCategory("Årets Berättare", required("Ange text")), maxLength(800, (length) => `Texten är för lång, max ${length} tecken.`)],
      "inskickad-av": [required("Ange ditt namn")],
      "inskickad-av-epost": [required("Ange din e-postadress"), email("E-postadressen är inte korrekt formaterad")],
      acceptTerms: [required("Villkoren måste accepteras för att nominera.")],
    },
    form
  );

  const inputRefs = useRef(
    typedKeys(form).reduce((acc, key) => {
      acc[key] = createRef();
      return acc;
    }, {} as { [key in keyof typeof form]: MutableRefObject<any> })
  );

  const changeValue = useCallback(
    <Key extends keyof typeof form>(key: Key, value: (typeof form)[Key]) => {
      const newValue = { [key]: value };
      setForm((form) => ({ ...form, ...newValue }));
    },
    [setForm]
  );

  const setDirtyField = useCallback((key: keyof typeof form) => setDirtyFields((fields) => ({ ...fields, [key]: true })), []);

  const [submissionStatus, setSubmissionStatus] = useState("awaiting" as "awaiting" | "submitting" | "errored");

  const submitForm = useCallback(
    async (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (ev.target == null) {
        throw new Error("Can't submit form element, no form in event");
      }
      setDirtyFields((fields) => copyObjectWithPropValue(fields, true));
      if (typedKeys(errors).length > 0) {
        inputRefs.current?.[typedKeys(errors)?.[0]].current.focus();
        return;
      }

      setSubmissionStatus("submitting");
      const formData = new FormData(ev.target as HTMLFormElement);

      //Netlify can't accpet files with non asci chars in file names
      //https://community.netlify.com/t/internal-server-error-500-when-submitting-form/12523
      const file = formData.get("file");
      if (file instanceof File) {
        formData.set("file", file, file.name.replace(/[^\x00-\x7F]/g, ""));
      }
      await fetch("/", {
        method: "post",
        body: formData,
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Nomination post failes with status ${res.status}: ${res.statusText}. Response contenttype was ${res.headers.get("Content-Type")}`);
          }
          setComplete();
        })
        .catch((_err) => {
          setSubmissionStatus("errored");
        });
    },
    [errors]
  );

  const sharedFieldProps = {
    changeValue,
    setDirtyField,
    refs: inputRefs.current,
    form,
  };

  const getDisplayError = useCallback((key: keyof typeof form) => dirtyFields[key] && errors[key], [errors, dirtyFields]);
  const categoryNames = useMemo(() => Array.from(categories.keys()), [categories]);
  return (
    <>
      <form
        onSubmit={submitForm}
        css={css`
          ${verticalMargin(44)}
          @media ${bpLarge} {
            ${verticalMargin(80)}
          }
        `}
      >
        <Field {...sharedFieldProps} name="form-name" labelText="Kategori" type="select" options={categoryNames} helpText={categories.get(form["form-name"])} errorText={getDisplayError("form-name")} />
        <Field {...sharedFieldProps} name="författare" labelText="Vem/Vilka vill du nominera" type="text" errorText={getDisplayError("författare")} helpText={"Namn på den eller de personer du föreslår."} />
        <Field {...sharedFieldProps} name="publiceringsdatum" labelText="Publiceringsdatum" type="date" helpText="Gärna det exakta datumet för publicering om möjligt, annars under vilken månad. Gäller publiceringsdatum 1 oktober 2023 till och med 30 september 2024." errorText={getDisplayError("publiceringsdatum")} />
        <Field {...sharedFieldProps} name="titel" labelText="Bidragets titel" type="text" errorText={getDisplayError("titel")} helpText="Titel på artikeln, reportaget, programmet, sajten, bloggen etc. som du föreslår." />
        <Field {...sharedFieldProps} name="fil" type="file" labelText="Material (om tillgängligt)" helpText="Här kan du ladda upp materialet som du vill nominera. OBS! Maxstorlek på filen är 6 MB. Ljud och rörlig bild kan inte laddas upp - ange länk i fältet nedan istället." />
        <Field {...sharedFieldProps} name="länk-till-material" labelText="Länk till material (om tillgängligt)" type="text" errorText={getDisplayError("länk-till-material")} />
        <Field
          {...sharedFieldProps}
          name="motivering"
          labelText="Motivering"
          type="textarea"
          helpText={
            <>
              Den här texten kommer att visas anonymt för juryn, skriv därför inte namnet på dig som nominerande i motiveringen
              <br />
              {lengthPresentation(form.motivering.length, contentMaxLong)}
            </>
          }
          errorText={getDisplayError("motivering")}
        />
        {(() => {
          switch (form["form-name"]) {
            case "Årets Avslöjande": {
              return (
                <>
                  <Field {...sharedFieldProps} name="avslöjande" labelText="Vad har avslöjats? (Max två meningar)" type="textarea" errorText={getDisplayError("avslöjande")} helpText={lengthPresentation(form.avslöjande.length, contentMidLong)} />
                  <Field {...sharedFieldProps} name="avslöjande-följd" labelText="Ange kortfattat vad avslöjandet lett till." type="textarea" errorText={getDisplayError("avslöjande-följd")} helpText={lengthPresentation(form["avslöjande-följd"].length, contentMaxLong)} />
                </>
              );
            }
            case "Årets Förnyare": {
              return (
                <>
                  <Field {...sharedFieldProps} name="nyskapande" labelText="På vilket sätt är det nominerade bidraget nytt eller nyskapande?" type="textarea" errorText={getDisplayError("nyskapande")} helpText={lengthPresentation(form.nyskapande.length, contentMaxLong)} />
                  <Field {...sharedFieldProps} name="nyskapande-för-konsument" labelText="Vilken betydelse har bidraget haft för konsumenter och användare?" type="textarea" errorText={getDisplayError("nyskapande-för-konsument")} helpText={lengthPresentation(form["nyskapande-för-konsument"].length, contentMaxLong)} />
                </>
              );
            }
            case "Årets Berättare": {
              return <Field {...sharedFieldProps} name="berättare" labelText="Vad är det som gör den nominerade berättaren enastående och unik?" type="textarea" errorText={getDisplayError("berättare")} helpText={lengthPresentation(form.berättare.length, contentMaxLong)} />;
            }
          }
        })()}
        <Field {...sharedFieldProps} name="inskickad-av" labelText="Ditt namn" type="text" errorText={getDisplayError("inskickad-av")} />
        <Field {...sharedFieldProps} name="inskickad-av-epost" labelText="Din e-postadress" type="text" errorText={getDisplayError("inskickad-av-epost")} />
        <Field
          {...sharedFieldProps}
          name="acceptTerms"
          labelText={
            privacyPolicyUrl && (
              <>
                Jag har tagit del av{" "}
                <Link
                  to={privacyPolicyUrl}
                  css={css`
                    text-decoration: underline;
                  `}
                >
                  informationen om behandling av personuppgifter
                </Link>{" "}
                och lämnar härmed mitt uttryckliga samtycke till att mina personuppgifter behandlas för att kunna utföra de tjänster som jag efterfrågar.
              </>
            )
          }
          type="checkbox"
          errorText={getDisplayError("acceptTerms")}
        />
        <FormButton css={verticalMargin(14)} disabled={submissionStatus === "submitting"}>
          {submissionStatus === "submitting" ? "Skickar nominering" : "Skicka in nominering"}
        </FormButton>
        {submissionStatus === "errored" && (
          <p>
            Nomineringen kunde inte tas emot. <br />
            Vi undersöker problemet. Var god försök igen senare.
          </p>
        )}
      </form>
      <NetlifyHardcodedForms categoryNames={categoryNames} />
    </>
  );
}

type FieldSetProps<Fields> = {
  form: Fields;
  refs: { [p in keyof Fields]: MutableRefObject<any> };
  name: keyof Fields;
  labelText: ReactNode;
  helpText?: ReactNode;
  changeValue: <Key extends keyof Fields>(key: Key, value: Fields[Key]) => void;
  setDirtyField: <Key extends keyof Fields>(key: Key) => void;
  errorText?: string | false;
} & ({ type: "text" | "textarea" | "file" | "date" | "checkbox" } | { type: "select"; options: readonly string[] });

type FieldChange = ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;

const Field = <TForm extends { [key: string]: any }>({ form, name, labelText, helpText, refs, changeValue, setDirtyField, errorText, ...field }: FieldSetProps<TForm>) => {
  const sharedProps = {
    ref: refs[name],
    name: name as string,
    value: form[name],
    onBlur: (ev: FieldChange) => {
      setDirtyField(ev.target.name);
    },
    onChange: (ev: FieldChange) => {
      //@ts-ignore: Don't send the wrong value here.
      changeValue(ev.target.name, ev.target.value);
    },
    hasError: !!errorText,
  };
  return (
    <FieldSet>
      {field.type !== "checkbox" && <Label htmlFor={name as string}>{labelText}</Label>}
      {field.type === "select" ? (
        <Select {...sharedProps}>
          {field.options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : field.type === "textarea" ? (
        <TextArea {...sharedProps} />
      ) : field.type === "file" ? (
        <div>
          <input
            type="file"
            css={{ display: "none" }}
            {...omit(sharedProps, "value")}
            onChange={(ev) => {
              //@ts-ignore: places to go, people to see
              changeValue(name, ev.target.files?.[0] ?? null);
            }}
          />
          {form[name] != null && "name" in form[name] && (
            <small
              css={css`
                display: block;
                ${verticalMargin(6)}
                font-family: ${captionTypeface};
                font-size: 14px;
                line-height: 14px;
                @media ${bpLarge} {
                  font-size: 16px;
                }
              `}
            >
              {form[name].name}
            </small>
          )}
          <Button
            css={css`
              ${verticalMargin(8)}
            `}
            onClick={(ev) => {
              ev.preventDefault();
              if (refs[name].current != null) {
                //@ts-ignore: places to go, people to see
                changeValue(name, null);
                refs[name].current.value = null;
                refs[name].current.click();
              }
            }}
          >
            Välj{form[name] ? " annan" : ""} fil
          </Button>
        </div>
      ) : field.type === "checkbox" ? (
        <div
          css={css`
            display: flex;
            flex-direction: "row";
          `}
        >
          <input
            css={css`
              margin-left: 1px;
              margin-top: 5px;
              margin-right: 10px;
              transform: scale(1.2);
            `}
            type="checkbox"
            id={name as string}
            checked={!!form[name]}
            onChange={(ev) => changeValue(name, ev.target.checked as any)}
          />
          <label htmlFor={name as string}>{labelText}</label>
        </div>
      ) : (
        <Input key="input" type={field.type} {...sharedProps} />
      )}
      {errorText ? <InputCaption css={{ color: "#8B7C58" }}>{errorText}</InputCaption> : helpText && <InputCaption>{helpText}</InputCaption>}
    </FieldSet>
  );
};

const inputCss = (hasError: boolean) => css`
  display: block;
  width: 100%;
  padding: 14px 16px;
  border: 1px solid ${!hasError ? "#b7b7b7" : colorGoldStroke};
  outline-width: 2px;
  background: white;
  border-radius: 0;
  appearance: none;
  font-size: 17px;
  line-height: 20px;
  box-sizing: border-box;
  ${verticalMargin(2)}
  background-position: right top;
  background-repeat: no-repeat;
  background-origin: content-box;
  ${hasError && {
    backgroundImage: `url(${exclamation})`,
  }}
  ${hasError && {
    boxShadow: `0 0 0 1.5px ${colorGoldStroke}`,
  }}
  transition-property: border, box-shadow, background-image;
  transition-duration: 200ms;
  @media ${bpLarge} {
    font-size: 19px;
    line-height: 22px;
  }
`;

type InputProps = {
  hasError: boolean;
};

const Input = forwardRef<HTMLInputElement, ComponentProps<"input"> & InputProps>(({ hasError, ...props }, ref) => <input css={inputCss(hasError)} {...props} ref={ref} />);

const Select = forwardRef<HTMLSelectElement, ComponentProps<"select"> & InputProps>(({ hasError, ...props }, ref) => (
  <select
    css={[
      inputCss(hasError),
      css`
        background-image: url(${selectArrow});
      `,
    ]}
    {...props}
    ref={ref}
  />
));

const TextArea = forwardRef<HTMLTextAreaElement, ComponentProps<"textarea"> & InputProps>(({ hasError, ...props }, ref) => (
  <textarea
    ref={ref}
    css={[
      inputCss(hasError),
      css`
        resize: vertical;
      `,
    ]}
    rows={4}
    {...props}
  />
));

const FieldSet = (props: ComponentProps<"fieldset">) => (
  <fieldset
    css={css`
      display: block;
      padding: 0;
      margin: 32px 0;
      border: none;
      @media ${bpLarge} {
        margin: 44px 0;
      }
    `}
    {...props}
  />
);

const Label = styled.label`
  font-family: ${displayTypeface};
  font-weight: 700;
  font-size: 17px;
  @media ${bpLarge} {
    font-size: 19px;
  }
`;

const InputCaption = styled.small`
  display: block;
  font-family: ${captionTypeface};
  color: #828282;
  font-size: 14px;
  ${verticalMargin(6)}
  @media ${bpLarge} {
    font-size: 16px;
  }
`;

/* Print forms and fields for netlify to pick up. This is crap, but needed for data to be registered in Netlify */
const NetlifyHardcodedForms = ({ categoryNames }: { categoryNames: Category[] }) => (
  <>
    {categoryNames.map((category) => (
      <form key={category} name={category} data-netlify="true" style={{ display: "none" }}>
        <input name="författare" type="text" />
        <input name="publiceringsdatum" type="date" />
        <input name="titel" type="text" />
        <input name="fil" type="file" />
        <input name="länk-till-material" type="text" />
        <textarea name="motivering" />
        {(() => {
          switch (category) {
            case "Årets Avslöjande": {
              return (
                <>
                  <textarea name="avslöjande" />
                  <textarea name="avslöjande-följd" />
                </>
              );
            }
            case "Årets Förnyare": {
              return (
                <>
                  <textarea name="nyskapande" />
                  <textarea name="nyskapande-för-konsument" />
                </>
              );
            }
            case "Årets Berättare": {
              return <textarea name="berättare" />;
            }
          }
        })()}
        <input name="inskickad-av" type="text" />
        <input name="inskickad-av-epost" type="text" />
      </form>
    ))}
  </>
);
